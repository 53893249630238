import axios from 'axios'

export const getAuthToken = () => {
  return window.localStorage.getItem('auth_token')
}

export const setAuthHeader = (token) => {
  window.localStorage.setItem('auth_token', token)
}

const backendBaseURL = process.env.REACT_APP_BACKEND_BASE_URL || ''

axios.defaults.baseURL = backendBaseURL
axios.defaults.headers.post['Content-Type'] = 'application/json'

export const request = (method, url, data, headers = {}) => {
  if (getAuthToken() !== null && getAuthToken() !== 'null') {
    headers['Authorization'] = `Bearer ${getAuthToken()}`
  }
  return axios({
    method,
    url,
    headers,
    data,
  })
}
