import React, { createContext, useState, useEffect } from 'react'
import { getAuthToken, request } from '../axios_helper'

const UserContext = createContext()

// eslint-disable-next-line react/prop-types
const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  const logout = () => {
    setUser(null)
  }

  useEffect(() => {
    if (user !== undefined && user !== null && user !== '') {
      return
    }
    if (getAuthToken() === null || getAuthToken() === 'null') {
      return
    }
    async function fetchData() {
      return await request('GET', `/auth/profile`, null)
    }
    fetchData()
      .then((response) => {
        setUser(response.data)
      })
      .catch((error) => {
        setUser(null)
      })
  }, [user, setUser])

  return <UserContext.Provider value={{ user, logout }}>{children}</UserContext.Provider>
}

export { UserContext, UserContextProvider }
